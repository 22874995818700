import { render, staticRenderFns } from "./EmpDetails.vue?vue&type=template&id=d60f81de&scoped=true"
import script from "./EmpDetails.vue?vue&type=script&lang=js"
export * from "./EmpDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d60f81de",
  null
  
)

export default component.exports