<template>
  <div class="mt-2 px-0">
    <v-card tile class="pa-3" flat>
      <v-card-text class="py-0 my-0 mb-3 pl-0">
        <v-row class="gutters-7">
          <v-col cols="12" sm="12" md="12" lg="8" xl="8">
            <v-row class="no-gutters">
              <v-col cols="12" sm="12" md="3" lg="3" xl="2">
                <v-card-title class="d-flex align-center black--text">
                  Employee Details
                </v-card-title>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                xl="10"
                class="py-2 d-flex align-center justify-sm-end fullWidth"
              >
                <v-text-field
                  label="Name"
                  width="400px"
                  placeholder="Name"
                  v-model="item.name"
                  outlined
                  dense
                  class="px-4"
                  hide-details
                ></v-text-field>
                <v-text-field
                  label="Email"
                  width="400px"
                  placeholder="Email"
                  v-model="item.email"
                  outlined
                  dense
                  class="px-4"
                  hide-details
                ></v-text-field>
                <v-select
                  :items="roles"
                  style="width: 223px"
                  v-model="item.role_id"
                  item-text="name"
                  item-value="role_id"
                  label="Role"
                  name="role_id"
                  outlined
                  dense
                  class="px-4"
                  hide-details
                ></v-select>
                <span>
                  <v-btn class="mx-3 primary" @click="update()">save</v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            class="py-3 d-flex align-center justify-end"
          >
            <v-text-field
              v-model.lazy="search"
              prepend-inner-icon="mdi-magnify"
              :append-icon="search ? 'mdi-close' : ''"
              @click:append="clearSearch"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              class="mr-4 border-radius-6"
              :style="{ maxWidth: '300px' }"
              v-debounce="500"
            ></v-text-field>
            <v-btn
              color="primary"
              dark
              class="px-3 border-radius-6 custom-data-table-action-btn"
              @click="addProjects()"
            >
              <v-icon class="mr-1"> mdi-plus </v-icon>
              Add Projects
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text class="py-0 my-0">
        <div class="py-3 d-flex align-center">
          <v-card-title class="d-flex align-center px-2 py-0">
            Project List
          </v-card-title>
        </div>
      </v-card-text> -->
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="projects"
        :search="search"
        class="custom-data-table mx-4"
      >
        <template v-slot:item.name="{ item }">
          <div>
            <Avatar
              :size="'30px'"
              :color="item.project_color_hex || 'blue lighten-3'"
              :intials="item.name ? item.name.charAt(0).toUpperCase() : 'P'"
              class="mr-2 my-3"
            />
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.estimated_time_for_developement="{ item }">
          <div>
            <v-chip
              v-if="estimated_time_for_developement > 0"
              style="
                height: 28px;
                width: 60px; /* Set a fixed width */
                border-radius: 6px;
                text-align: center !important;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
              "
              label
              color="primary"
            >
              {{ item.estimated_time_for_developement }}h
            </v-chip>
            <v-chip
              style="
                height: 28px;
                width: 60px; /* Set a fixed width */
                border-radius: 6px;
                text-align: center !important;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
              "
              label
              color="primary"
              v-else
            >
              0
            </v-chip>
          </div>
        </template>

        <template v-slot:item.total="{ item }">
          <v-chip
            style="
              height: 28px;
              width: 60px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            color="primary"
          >
            <span v-if="item.totalWorkingMinute / 60 > 0">{{
              item.totalWorkingMinute / 60
            }}</span>
            <span v-else> 0 </span>
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="removeProject(item.project_id)"
              >
                <TrashIcon :size="19" />
              </v-btn>
            </template>
            <span>Remove Project</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <Dialog
      :isOpen="addProjectDialog"
      @close="closeAddProjectDialog"
      title="Project List"
      :save="saveProjects"
      :action="true"
    >
      <template>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-autocomplete
              chips
              clearable
              outlined
              dense
              deletable-chips
              multiple
              small-chips
              label="Select Assign Project"
              @input="searchInput = null"
              :search-input.sync="searchInput"
              v-model="addproject"
              :items="projectList"
              item-text="name"
              item-value="project_id"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </Dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import { debounce } from "lodash";
import Avatar from "../common/basic/Avatar.vue";
import debounce from "v-debounce";
import TrashIcon from "../../assets/icons/TrashIcon.vue";
import Dialog from "../common/basic/Dialog.vue";

export default {
  data: () => {
    return {
      employee_id: "",
      item: {
        name: "",
        email: "",
        password: "",
        role_id: "",
      },
      roles: [],
      message: "",
      snackbar: false,
      searchInput: null,
      headers: [
        {
          text: "Project List",
          align: "start",
          value: "name",
          width: "60%",
        },
        {
          text: "Total Hours Working",
          value: "estimated_time_for_developement",
          width: "10%",
          align: "start",
        },
        {
          text: "Total spent time",
          value: "total",
          width: "10%",
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          align: "start",
          width: "10%",
        },
      ],
      projects: [],
      projectList: [],
      search: "",
      loading: false,
      addProjectDialog: false,
      addproject: null,
    };
  },
  components: {
    Avatar,

    TrashIcon,
    Dialog,
  },
  directives: {
    debounce,
  },
  methods: {
    getEmployeeDetail(id) {
      this.$axios.get("employee/" + id).then((res) => {
        if (res.data.success) {
          this.item = res.data.employee;
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
        }
      });
    },
    clearSearch() {
      this.search = "";
    },
    getRoles() {
      let _self = this;

      this.$axios.post("roles").then((res) => {
        let resdata = res.data;

        if (resdata.success) {
          _self.roles = resdata.roles;
        } else {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },
    update() {
      this.loading = true;
      this.$axios.put("/employee/" + this.item.id, this.item).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          }
          _self.showEmployeeAddEditDialog = true;
        } else {
          _self.snackbar = true;
          _self.message = data.message;
        }
        _self.loading = false;
      });
    },
    get_project_list(id) {
      this.loading = true;
      this.$axios.get("employee/project/" + id).then((res) => {
        this.projects = res.data.projects;
        this.loading = false;
      });
    },
    removeProject(id) {
      let _self = this;
      this.isDeletingMember = true;

      _self.$axios
        .put("/remove_emp_project/" + this.employee_id, { project_id: id })
        .then((res) => {
          let resdata = res.data;

          if (resdata.success) {
            this.projects = this.projects.filter((item) => {
              if (item.project_id != id) {
                return item;
              }
            });
          } else {
            _self.snackbarError = true;
            _self.message = resdata.message;
          }
        })
        .finally(() => {
          this.isDeletingMember = false;
        });
    },
    async get_all_project() {
      this.loading = true;
      await this.$axios.get("project").then((res) => {
        this.projectList = res.data.projects;
        this.loading = false;
      });
    },
    addProjects() {
      this.get_all_project().then(() => {
        const arrayColumn = (arr, n) => arr.map((x) => x[n]);
        let addProjectId = arrayColumn(this.projects, "project_id");
        this.projectList = this.projectList.filter((item) => {
          if (!addProjectId.includes(item.project_id)) {
            return item;
          }
        });

        this.addProjectDialog = true;
      });
    },
    closeAddProjectDialog() {
      this.addProjectDialog = false;
      this.addproject = null;
    },
    saveProjects() {
      this.loading = true;
      let objData = {
        project_id: this.addproject,
        employee_id: this.employee_id,
        employee_role: this.item.roles,
      };
      this.$axios.post("assignee_projects", objData).then((res) => {
        if (res.data.success) {
          this.get_project_list(this.employee_id);
          this.loading = false;
          this.closeAddProjectDialog();
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.getRoles();
    this.employee_id = this.$route.params.id;
    this.getEmployeeDetail(this.employee_id);
    this.get_project_list(this.employee_id);
  },
};
</script>

<style scoped lang="scss"></style>
