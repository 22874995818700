<template>
  <div>
    <div class="white d-flex justify-space-between align-center">
      <div
        class="d-flex align-center ma-0 pa-2 pl-7"
        style="gap: 10px; height: 56px"
      >
        <div>
          <v-autocomplete
            class="dark-placeholder"
            v-model="selectedProject"
            :items="allProjects"
            item-text="name"
            return-object
            dense
            solo
            outlined
            @change="filterEmpList"
            placeholder="All Projects"
            flat
            clearable
            item-value="project_id"
            hide-details
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-model="employees_id"
            :items="assignedEmp"
            item-text="name"
            outlined
            item-value="id"
            placeholder="Select Employee"
            class="d-inline-block custom-data-table-select-solo"
            dense
            solo
            clearable
            flat
            hide-details
            @change="filterTimeSheet"
            multiple
            style="max-width: 900px; font-size: 14px"
          />
        </div>
        <div>
          <v-autocomplete
            v-model="duration"
            :items="durationList"
            outlined
            placeholder="Select Duration"
            class="d-inline-block custom-data-table-select-solo"
            dense
            @change="filterTimeSheet"
            clearable
            flat
            hide-details
            style="max-width: 200px; font-size: 14px"
          />
        </div>
        <div
          class="d-flex justin-center align-center"
          v-if="duration === 'Custom'"
        >
          <div>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="start_datetime"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_datetime"
                  label="Select Start Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="start_datetime" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu2.save(start_datetime)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="end_datetime"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end_datetime"
                  label="Select End Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="end_datetime" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(end_datetime)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
        <div>
          <v-btn shaped depressed color="primary" @click="getWorkLogReport()">
            Search
          </v-btn>
        </div>
      </div>
      <div class="pr-7">
        <v-btn @click="downloadExcel" color="secondary">
          <ExportIcon /> Export</v-btn
        >
      </div>
    </div>

    <div
      v-if="displayData"
      class="d-flex justify-center align-center w-full ma-2"
    >
      <v-card tile class="pa-6" style="width: 100%" flat>
        <div class="d-flex flex-column align-center">
          <div class="d-flex flex-row align-center justify-center">
            <div class="text-body-1 ma-2">
              <span class="font-weight-medium">Project Name:</span>
              <span> {{ selectedProject?.name }}</span>
            </div>
            <div class="text-body-1 ma-2">
              <span class="font-weight-medium">Total Spent Time:</span>
              <span> {{ formattedProjectTotalHours }}</span>
            </div>
            <div class="text-body-1 ma-2">
              <span class="font-weight-medium">Total Estimation:</span>
              <span> {{ formattedProjectEstimatedHours }}</span>
            </div>
            <div class="text-body-1 ma-2">
              <span class="font-weight-medium">Spent Time:</span>

              <span>{{
                `(  ${projectLastWorkLog} to ${projectLastWorkLog}):`
              }}</span>

              <span> {{ filteredProjectHours }}</span>
            </div>
          </div>
          <!-- <chart-data></chart-data> -->
        </div>
      </v-card>
    </div>

    <div class="mt-2">
      <v-card tile class="pa-3 pt-8" flat>
        <v-data-table
          :headers="headers"
          :items="workReportList"
          item-key="id"
          :expanded.sync="expanded"
          class="custom-data-table mx-4 cus_table"
          show-expand
          expand-on-click="true"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{ formatDate(item.updated_at) }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              style="background-color: #f0f6fc"
              class="pa-2 pt-0"
            >
              <div class="row white ma-0">
                <div
                  class="col text-left secondary--text"
                  style="min-width: 15%; max-width: 15%"
                >
                  <span class="sub-header">Task Name</span>
                </div>
                <div
                  style="max-width: 15%; min-width: 15%"
                  class="col text-left secondary--text"
                >
                  <span class="sub-header">Task Status </span>
                </div>
                <div
                  class="col text-left secondary--text"
                  style="max-width: 15%; min-width: 15%"
                >
                  <span class="sub-header">Total Estimation Hours</span>
                </div>
                <div class="col text-left secondary--text">
                  <span class="sub-header">Task Detail </span>
                </div>
                <div class="col text-right secondary--text mr-4">
                  <router-link
                    :to="`/admin/timeSheet/edit/${item.date}/${item.employee_id}`"
                    ><PencilIcon />
                  </router-link>
                </div>
              </div>
              <div style="height: 5px"></div>
              <div class="w-100 white" :key="item.id">
                <div class="d-flex align-baseline px-5 py-6">
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ item.task_name }}
                    </span>
                  </div>
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ item?.task_status ? item?.task_status : null }}
                    </span>
                  </div>
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ item?.total_task_estimation }}
                    </span>
                  </div>
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ item?.description || `No Data` }}
                    </span>
                  </div>
                </div>
                <div
                  class="ma-0 pa-0"
                  style="background-color: #f0f6fc; height: 2px"
                ></div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import { mapMutations, mapState } from "vuex/dist/vuex.common.js";
import DateTimeFormate from "@/mixins/DateTimeFormate";
import * as XLSX from "xlsx";
// import ChartData from "../../shared/ChartData.vue";
export default {
  // components: { ChartData },
  mixins: [DateTimeFormate],
  data() {
    return {
      menu: false,
      menu2: false,
      loading: false,
      displayData: false,
      start_datetime: null,
      end_datetime: null,
      duration: "This Week",
      snackbarError: "",
      message: "",
      selectedProject: null,
      durationList: ["This Week", "This Month", "Last Month", "Custom"],
      assignedEmp: [],
      projectTotalHours: "",
      filteredProjectHours: "",
      formattedProjectTotalHours: "",
      totalEstimatedProjectHours: "",
      formattedProjectEstimatedHours: "",
      projectFirstWorkLog: "",
      projectLastWorkLog: "",
      workReportList: [],
      headers: [
        {
          text: "Project Name",
          align: "start",
          value: "project_name",
          width: "15%",
        },
        {
          text: "Employee",
          value: "employees_name",
          width: "15%",
        },
        {
          text: "Created Date",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Updated Date",
          value: "updated_at",
        },
        {
          text: "Spent Time",
          value: "time",
        },
        { text: "Actions", value: "data-table-expand", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("projects", ["allProjects"]),
    ...mapState("employees", ["employeesList", "cachesEmpData"]),
  },
  methods: {
    ...mapMutations("projects", ["setProjectData"]),
    ...mapMutations("employees", ["setEmployees", "isCachesEmpData"]),
    getEmployeeList() {
      if (this.cachesEmpData) {
        return;
      }
      let _self = this;
      this.loading = true;
      this.$axios.get("employee").then((res) => {
        this.loading = false;
        if (res.data.success) {
          _self.setEmployees(res.data.employees);
          _self.isCachesEmpData(true);
        } else {
          this.snackbarError = true;
          _self.message = res.data.message;
        }
      });
    },
    get_project_list() {
      this.loading = true;
      this.$axios.get("project").then((res) => {
        this.setProjectData(res.data.projects);
        this.loading = false;
      });
    },
    filterEmpList(project_id) {
      console.log(project_id, "project_id");
      this.displayData = false;
      this.projectTotalHours = null;
      this.totalEstimatedProjectHours = null;
      console.log(this.workReportList);
      this.assignedEmp.length = 0;
      this.employeesList.forEach((empDataObj) => {
        if (empDataObj?.projectIds?.includes(project_id.project_id)) {
          this.assignedEmp.push(empDataObj);
        }
      });
    },
    getWorkLogReport() {
      this.displayData = true;
      if (this.duration === "This Week") {
        this.start_datetime = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_datetime = moment().add(0, "days").format("YYYY-MM-DD");
      }
      if (this.duration === "This Month") {
        this.end_datetime =
          moment().add(0, "months").endOf("month").format("YYYY-MM-DD") || null;
        this.start_datetime =
          moment().add(0, "months").startOf("month").format("YYYY-MM-DD") ||
          null;
      }
      if (this.duration === "Last Month") {
        this.end_datetime =
          moment().add(-1, "months").endOf("month").format("YYYY-MM-DD") ||
          null;
        this.start_datetime = moment()
          .add(-1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
      }
      this.$axios
        .post("/work_log_report", {
          start_date: this.end_datetime,
          end_date: this.start_datetime,
          project_id: this.selectedProject.project_id,
          emp_id: this.employees_id ? this.employees_id : [],
        })
        .then((res) => {
          if (res.data.success) {
            this.workReportList = res?.data?.data;
            this.projectTotalHours = res?.data?.totalProjectHours;
            this.totalEstimatedProjectHours = res?.data?.totalProjectEstimation;
            console.log("0", res?.data?.firstWorkLog[0]?.created_at);
            this.projectFirstWorkLog = this.formatDate(
              res?.data?.firstWorkLog[0]?.created_at
            );
            this.projectLastWorkLog = this.formatDate(
              res?.data?.lastWorkLog[0]?.created_at
            );
            this.formattedProjectTotalHours = this.displayMessage(
              this.projectTotalHours?.total_taken_hours
            );
            console.log(this.totalEstimatedProjectHours?.total_estimation);
            this.formattedProjectEstimatedHours = this.displayMessage(
              this.totalEstimatedProjectHours?.total_estimation
            );
            let sumOfHrs = [];
            let hoursArr = this.workReportList.reduce(
              (previousValue, currentValue) => {
                sumOfHrs.push(currentValue.time);
                return sumOfHrs;
              },
              []
            );
            this.filteredProjectHours = this.calculateTime(hoursArr);
          } else {
            this.snackbarError = true;
          }
        });
    },
    formatDate(date) {
      if (!date) {
        return ""; // Return an empty string if the date is null or undefined
      }

      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        return ""; // Handle invalid dates
      }
      const d = new Date(date);
      return d.toISOString().split("T")[0]
        ? d.toISOString().split("T")[0]
        : null; // Returns YYYY-MM-DD
    },
    downloadExcel() {
      const worksheetData = this.workReportList.map((item) => ({
        "Project Name": item.project_name || "N/A",
        Employee: item.employees_name || "N/A",
        "Created Date": this.formatDate(item.created_at),
        "Updated Date": this.formatDate(item.updated_at),
        "Spent Time": item.time || "N/A",
        "Estimation Time": item?.total_task_estimation || "N/A",
      }));
      // Create a worksheet and a workbook
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Work Report");
      // Write the workbook and trigger the download
      XLSX.writeFile(workbook, "Work_Report.xlsx");
    },
  },

  mounted() {
    this.get_project_list();
    this.getEmployeeList();
  },
};
</script>

<style></style>
