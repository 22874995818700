<template>
  <v-container fluid>
    <v-btn
      fab
      small
      slot
      v-if="$vuetify.breakpoint.mdAndDown"
      @click="openDatePicker"
      class="dateIcon"
      ><v-icon>mdi mdi-calendar-range</v-icon></v-btn
    >
    <v-row class="d-flex align-start pa-4">
      <v-col class="col-md-12 col-lg-9">
        <v-row class="">
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="blue"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects' }"
                  >
                    <v-icon dark> mdi mdi-file-document-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ projects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Total Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#35CF96"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '3' } }"
                  >
                    <v-icon dark>mdi mdi-check-circle-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ completedProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Completed Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="orange"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '2' } }"
                  >
                    <v-icon dark>mdi mdi-clock-time-three-outline </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ inProgressProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >In Progress Project</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#FA9978"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '4' } }"
                  >
                    <v-icon dark>mdi mdi-hand-back-left-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ onHoldProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >On Hold</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="mr-3 pa-5 d-flex align-center"
              elevation="0"
              style="border-radius: 14px"
            >
              <v-row align="center" style="flex-wrap: nowrap">
                <v-col style="flex-grow: 0">
                  <v-btn
                    fab
                    dark
                    large
                    color="#F3425F"
                    style="height: 60px; width: 60px"
                    :to="{ name: 'projects', query: { status: '5' } }"
                  >
                    <v-icon dark>mdi mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <div>
                    <h2 style="color: #323338">{{ cancelProjects }}</h2>
                    <span
                      style="
                        font-size: 1rem !important;
                        font-weight: 500;
                        text-wrap: nowrap;
                        color: #323338;
                      "
                      >Cancel</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pr-3">
          <v-col>
            <v-card
              tile
              class="pa-3"
              style="border-radius: 12px !important"
              flat
            >
              <v-card-title>Project Due Dates</v-card-title>
              <v-divider />
              <v-card-text>
                <div
                  v-for="project in projectsFilterList"
                  :key="project.project_id"
                >
                  <div class="d-flex align-center justify-between">
                    <div style="flex: 1">
                      <div
                        style="cursor: pointer"
                        @click="redirect(project.project_id)"
                      >
                        <Avatar
                          :size="'30px'"
                          :color="project.project_color_hex || 'blue lighten-3'"
                          :intials="
                            project.name
                              ? project.name.charAt(0).toUpperCase()
                              : 'P'
                          "
                          class="mr-2 my-2"
                        />
                        <span
                          style="font-size: 14px; font-weight: 400"
                          class="secondary--text"
                        >
                          {{ project.name }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span
                        style="font-size: 14px; font-weight: 400"
                        class="secondary--text"
                      >
                        {{ new Date(project.end_date).toDateString() }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-md-0 col-lg-3" v-if="!$vuetify.breakpoint.smAndDown">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card style="border-radius: 12px !important" elevation="0">
              <v-card-text style="height: 80vh">
                <v-date-picker
                  v-model="date"
                  :events="dueDate"
                  full-width
                  header
                  event-color="red"
                  color="secondary"
                  scrollable
                >
                </v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :nudge-bottom="65"
    >
      <v-card style="border-radius: 12px !important" elevation="0">
        <v-card-text style="height: 80vh">
          <v-date-picker
            v-model="date"
            :events="dueDate"
            full-width
            header
            event-color="red"
            color="secondary"
            scrollable
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import moment from "moment";
import Avatar from "./common/basic/Avatar.vue";

export default {
  data() {
    return {
      employees: "",
      projects: "",
      completedProjects: "",
      cancelProjects: "",
      menu: false,
      onHoldProjects: "",
      inProgressProjects: "",
      tasks: "",
      itemsPerPage: 5,
      serverItems: [],
      loading: true,
      totalItems: 0,
      projectsList: [],
      projectsFilterList: [],

      date: moment().format("YYYY-MM-DD"),
      dueDate: [],
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "80%",
        },
        {
          text: "Due Date",
          value: "due_date",
        },
      ],
    };
  },
  components: {
    Avatar,
  },
  computed: {},
  watch: {
    date() {
      if (this.date) {
        this.projectsFilterList = this.projectsList.filter(
          (project) =>
            moment(project.end_date).format("YYYY-MM-DD") === this.date
        );
      }
    },
  },
  methods: {
    redirect(project_id) {
      this.$router.push({ path: "/projects/" + project_id }).catch(() => {});
    },
    openDatePicker() {
      this.menu = true;
    },
    getProject() {
      let _self = this;
      _self.loading = true;
      _self.$axios.get("/project").then((res) => {
        _self.loading = false;
        let resdata = res.data;

        _self.projectsList = resdata.projects;
        this.FilterProjects(resdata.projects);
        this.filterProjectsByMonth();
        if (!resdata.success) {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },
    FilterProjects(projects) {
      // const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      this.dueDate = projects.map((project) =>
        moment(project.end_date).format("YYYY-MM-DD")
      );
    },
    filterProjectsByMonth() {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      this.projectsFilterList = this.projectsList.filter((project) =>
        moment(project.end_date).isBetween(
          startOfMonth,
          endOfMonth,
          "day",
          "[]"
        )
      );
    },
    getDashboardCounts() {
      let _self = this;

      _self.$axios.get("dashboardCounts").then((response) => {
        if (response.data) {
          let data = response.data;

          _self.employees = data.counts.employees;
          _self.projects = data.counts.projects;
          _self.tasks = data.counts.tasks;
          _self.completedProjects = data.counts.completedProjects;
          _self.cancelProjects = data.counts.cancelProjects;
          _self.onHoldProjects = data.counts.onHoldProjects;
          _self.inProgressProjects = data.counts.inProgressProjects;
        }
      });
    },
  },
  mounted() {
    this.getDashboardCounts();
    this.getProject();
  },
};
</script>

<style>
/* .v-date-picker-header {
  display: none;
} */
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}
.dateIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 100;
}
v-container {
  position: relative;
}
</style>
