const state = {
  syncEmployees: false,
  employeesList: [],
  cachesEmpData: false,
};

const mutations = {
  setSyncEmployees(state, data) {
    state.syncEmployees = data.syncEmployees;
  },
  setEmployees(state, data) {
    state.employeesList = data.sort((a, b) => {
      return a.name.localeCompare(b.name); // sort by name
    });
  },
  isCachesEmpData(state, data) {
    state.cachesEmpData = data;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
